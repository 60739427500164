import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = [];
  const pageSize = 5;

  const startPage = Math.floor((currentPage - 1) / pageSize) * pageSize + 1;
  const endPage = Math.min(startPage + pageSize - 1, totalPages);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <button
        key={i}
        onClick={() => onPageChange(i)}
        style={{
          margin: '0 5px',
          padding: '5px 10px',
          backgroundColor: i === currentPage ? '#007bff' : '#fff',
          color: i === currentPage ? '#fff' : '#007bff',
          border: '1px solid #007bff',
          cursor: 'pointer',
        }}
      >
        {i}
      </button>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      {currentPage > 1 && (
        <button onClick={() => onPageChange(currentPage - 1)} style={{ marginRight: '10px' }}>
          이전
        </button>
      )}
      {pages}
      {endPage < totalPages && (
        <button onClick={() => onPageChange(endPage + 1)} style={{ marginLeft: '10px' }}>
          다음
        </button>
      )}
    </div>
  );
};

export default Pagination;
