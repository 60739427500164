import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "./modal.scss";
import {
  adminRequestPost,
  adminUserPut,
  requestGet,
} from "../../../apis/request";
import { set } from "lodash";

function UnRefundSubscribeModal({
  setModal,
  data,
  callRefundModal
}) {
  const [value, setValue] = useState([]);
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('ko-KR', options).format(new Date(dateString));
  };

  // 가격을 3자리마다 쉼표를 추가하여 포맷팅하는 함수
  const formatPrice = (price) => {
    return new Intl.NumberFormat('ko-KR', { style: 'decimal' }).format(price) + "원";
  };

  const callRefund = async (subscriptionId) => {
    // if (window.confirm("기간제 상품을 환불시키겠습니까?")) {
    //   try {
    //     let body = {
    //       id: subscriptionId,
    //     };
    //     await adminUserPut(`/api/subscription/refund`, body);
    //     alert("기간제 상품을 환불하였습니다.");
    //     setModal(false);
    //     callInfo();
    //   } catch (error) {
    //     if (error.data.error) {
    //       alert(error.data.error);
    //     } else if (error.data.message) {
    //       alert(error.data.message);
    //     } else {
    //       alert("요청 실패 하였습니다.");
    //     }
    //   }
    // }
    setModal(false);
    callRefundModal(subscriptionId);
  };


  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);



  const onClose = async () => {
    setModal(false);
  };



  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              <h2>기간제 결제 내역</h2>
              <table striped className="mt-3 border-table" >
                <thead>
                  <tr>
                    <th>상품명</th>
                    <th>가격</th>
                    <th>구매일</th>
                    <th>환불</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} >
                      <td>{item.name}</td>
                      <td style={{ textAlign: 'right' }} >{formatPrice(item.price)}</td>
                      <td>{formatDate(item.purchaseDate)}</td>
                      <td>
                      <button
                          onClick={(e) =>callRefund(item.id) }
                          className="btn btn-dark btn-sm"
                        >
                          환불
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row className="w-100 m-0 mt-3">
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onClose()}
                  >
                    닫기
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default UnRefundSubscribeModal;
