import React, { useRef, useEffect, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import MetaTags from "react-meta-tags";
import {
  formPost,
} from "../../apis/request";

import {
  Button,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";

export default function CoupleReviewWrite(props) {
  const reviewType = props.match.path.indexOf('coupleReview') !== -1 ? 1 : 2;
  const writeComp = props.match.path.indexOf('write') !== -1 ? 1 : 0;
  const boardNo = props.match.params.id;

  const editor = useRef(null);
  const titleRef = useRef(null);
  const listSummaryRef = useRef(null);
  const thumbnailRef = useRef(null);
  const pointRef = useRef(null);
  const [mainView, setMainView] = useState(0);
  const [view, setView] = useState(1);
  const [viewData, setViewData] = useState(null);



  const fontArr = ['맑은 고딕', '돋움', '바탕', 'Arial', 'Comic Sans MS', 'Courier New', 'Impact', 'Georgia', 'tahoma', 'Trebuchet MS', 'Verdana'];

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    files.map((file, index) => uploadImage(file, index, uploadHandler));
    uploadHandler();
  };

  const uploadImage = async (file, index, uploadHandler) => {
    const formData = new FormData();
    formData.append('files', file);

    try {
      const prResponse = await formPost(
        `/api/file/uploads/review`,
        formData
      );
      
      const response = {
        "result": [
          {
            "url":`https://d3txsylzm2ga7z.cloudfront.net/${prResponse.data[0].mainPath}`,
          },
        ]
      };
      const imgNode = document.createElement('img');
      imgNode.src = response.result[0].url;
      editor.current.core.insertNode(imgNode);
      
    } catch (error) {
      console.error("Error uploading image", error);
      throw error;
    }
  };

  async function getData(){
    const token = localStorage.getItem("merriageAgency-access");
    //const data = await fetch(`http://localhost:4000/api/review/view?id=${boardNo}`, {
    const data = await fetch(`${process.env.REACT_APP_API_HOST}/api/review/view?id=${boardNo}`, {
    //const data = await fetch(`https://manager.coincidence.co.kr/api/review/view?id=${boardNo}`, {
      headers: {
        'x-access-token': token,
        withCredentials: true,
      },
      method: 'GET',
    });
    const result = await data.json();
    setViewData(result.data.data[0]);
    setView(result.data.data[0].view);
    setMainView(result.data.data[0].mainView);
  }


  useEffect(() => {
    if(writeComp === 0){
      getData();
    }
  }, []);

  async function onSbm(e){
    e.preventDefault();
    
    const titleValue = titleRef.current.value;
    const thumbnailFiles = thumbnailRef.current.files;
    const editorContent = editor.current.getContents();
    const pointValue = pointRef.current.value;
    const listSummaryValue = listSummaryRef.current.value;

    

    if(titleValue === ''){
      alert('제목을 입력해주세요.');
      return false;
    }
    if(thumbnailFiles.length === 0){
      if(writeComp === 0 && viewData.thumbnail !== ''){
      }else{
        alert('썸네일 이미지를 선택해주세요.');
        return false;
      }
    }
    if(editorContent === '<p><br></p>'){
      alert('내용을 입력해주세요.');
      return false;
    }
    if(listSummaryValue === ''){
      alert('요약 내용을 입력해주세요.');
      return false;
 
    }

    const formData = new FormData();
    formData.append('title', titleValue);
    if (thumbnailFiles.length > 0) {
      formData.append('thum', thumbnailFiles[0]);
    }
    formData.append('content', editorContent);
    formData.append('reviewType', reviewType);
    formData.append('mainView', mainView);
    formData.append('view', view);
    formData.append('point', pointValue);
    formData.append('listSummary', listSummaryValue);

    if(writeComp === 0){
      formData.append('no', boardNo);
    }

    const token = localStorage.getItem("merriageAgency-access");

    //const url = writeComp === 1 ? `http://localhost:4000/api/review/write` : `http://localhost:4000/api/review/edit`
    const url = writeComp === 1 ? `${process.env.REACT_APP_API_HOST}/api/review/write` : `${process.env.REACT_APP_API_HOST}/api/review/edit`
    //const url = writeComp === 1 ? `https://manager.coincidence.co.kr/api/review/write` : `https://manager.coincidence.co.kr/api/review/edit`
    const data = await fetch(url, {
      headers: {
        'x-access-token': token,
        withCredentials: true,
      },
      method: 'POST',
      body: formData,
    });
    const result = await data.json();
    
    window.location.href = `/${reviewType === 1 ? 'couple' : 'party'}Review/list`;

  }
  async function reveiwDel(){
    if(window.confirm('선택된 데이터가 삭제됩니다.')){
      const token = localStorage.getItem("merriageAgency-access");
      //const data = await fetch(`http://localhost:4000/api/review/del`, {
      const data = await fetch(`${process.env.REACT_APP_API_HOST}/api/review/del`, {
      //const data = await fetch(`https://manager.coincidence.co.kr/api/review/del`, {
      
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
          withCredentials: true,
        },
        method: 'POST',
        body: JSON.stringify({no : boardNo}),
      });
      
      const result = await data.json();
      if(result.data.data === 1){
        window.location.href = `/${reviewType === 1 ? 'couple' : 'party'}Review/list`;
      }else{}
    }
  }

  if (writeComp === 0 && !viewData) {
    return (
      <div className="custompage-content">
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>{reviewType === 1 ? '커플' : '파티'} 후기</title>
        </MetaTags>
        <div className="row">
          <div className="col-12 col">
            <form onSubmit={(e) => onSbm(e)}>
              <div className="card">
                <div className="card-body">
                  <div><h2>{reviewType === 1 ? '커플' : '파티'} 후기</h2></div>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <div>
                        <label htmlFor={`title`} className="labelText">제목</label>
                        <input
                          type="text" id="title"
                          className="js-input-mask form-control customInput"
                          ref={titleRef} defaultValue={writeComp === 0 ? viewData.title: ''}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <div>
                        <label htmlFor={`point`} className="labelText">평점</label>
                        <select id="point" className="js-input-mask form-control customInput" 
                        ref={pointRef}
                        defaultValue={writeComp === 0 ? viewData.point: '5'}
                        >
                          <option value="5">5</option>
                          <option value="4">4</option>
                          <option value="3">3</option>
                          <option value="2">2</option>
                          <option value="1">1</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <label htmlFor={`thum`} className="labelText">
                        썸네일 이미지
                      </label>
                      {writeComp === 0 ? 
                        <p><img src={`https://d3txsylzm2ga7z.cloudfront.net/${viewData.thumbnail}`} alt="Thumbnail" /></p> :
                        ''
                      }
                      
                      <Label className="fileAddName m-0 h-100 d-flex align-items-center">
                        <Input
                          type="file"
                          id="thum"
                          innerRef={thumbnailRef}
                        />
                        <span
                          style={{
                            backgroundColor: "#FF5913",
                            border: "1px solid #FF5913",
                            padding: "8px 12px",
                            borderRadius: "5px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          업로드
                        </span>
                      </Label>
                    </Col>
                  </Row>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <div>
                        <label htmlFor={`listSummary`} className="labelText">요약 내용</label>
                        <textarea
                          type="text" id="listSummary"
                          className="js-input-mask form-control customInput"
                          ref={listSummaryRef} defaultValue={writeComp === 0 ? viewData.listSummary: ''}
                          placeholder="사용자 리스트 페이지에 출력됩니다."
                        >
                        </textarea>
                      </div>
                    </Col>
                  </Row>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <SunEditor
                        lang="ko"
                        setOptions={{
                          height: 300,
                          font: fontArr,
                          imageMultipleFile: true,
                          buttonList: [
                            ['undo', 'redo'],
                            ['font', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/',
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image', 'video'],
                            ['codeView'],
                          ],
                        }}
                        onImageUploadBefore={handleImageUploadBefore}
                        getSunEditorInstance={getSunEditorInstance}
                        setContents={writeComp === 0 ? viewData.content : ''}
                      />
                    </Col>
                  </Row>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <div>
                        <p className="labelText">메인페이지 노출여부</p>
                        <p>
                          <label htmlFor="mainView1">
                            <input
                              type="radio"
                              id="mainView1"
                              name="mainView"
                              value={1}
                              onClick={(e) => setMainView(Number(e.target.value))}
                              checked={mainView === 1} 
                            /> 노출
                          </label>
                          <label htmlFor="mainView2">
                            <input
                              type="radio"
                              id="mainView2"
                              name="mainView"
                              value={0}
                              onClick={(e) => setMainView(Number(e.target.value))}
                              checked={mainView === 0} 
                            /> 노출 안함
                          </label>
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <div>
                        <p className="labelText">게시물 숨김</p>
                        <p>
                          <label htmlFor="view1">
                            <input
                              type="radio" id="view1" name="view" value={0}
                              onClick={(e) => setView(Number(e.target.value))}
                              checked={view === 0}
                            /> 숨김
                          </label>
                          <label htmlFor="view2">
                            <input
                              type="radio" id="view2" name="view" value={1}
                              onClick={(e) => setView(Number(e.target.value))}
                              checked={view === 1}
                            /> 출력
                          </label>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button
                    color="primary"
                    className="me-2"
                    onClick={(e) => onSbm(e)}
                  >
                    적용
                  </Button>
                  {writeComp === 0 ?
                    <Button
                    color="primary"
                    className="me-2"
                    onClick={reveiwDel}
                  >
                    삭제
                  </Button>
                  : ''
                  }
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
