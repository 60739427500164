import PropTypes from "prop-types";
import React from "react";
import MetaTags from "react-meta-tags";

import { Row, Col, Alert, Container, Card } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions";

// import images
import { adminRequestPost } from "../../apis/request";
import { useDispatch } from "react-redux";

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    try {
      let body = { loginId: values.email, password: values.password };
      const loginResponse = await adminRequestPost(`/api/auth/signin`, body);
      console.log("loginResponse",loginResponse);
      localStorage.setItem("loginResponse",JSON.stringify(loginResponse) );
      dispatch(loginUser());
      localStorage.setItem("merriageAgency-access", loginResponse.accessToken);
      if(loginResponse.user && loginResponse.user.name){
        localStorage.setItem("loginUserName", loginResponse.user.name); 
        if(loginResponse.user.roles &&  loginResponse.user.roles.includes('manager') ){
          localStorage.setItem("loginUserType", "manager"); 
        } else {
          localStorage.setItem("loginUserType", "admin"); 
        }

      } else {
        localStorage.setItem("loginUserType", "manager"); 
        localStorage.setItem("loginUserName", "관리자"); 
      }
      let type = localStorage.getItem("loginUserType");
      if(type === "manager"){
        history.push("/consult/member/list");
      } else {
        history.push("/consult/list");
      }
      // history.push("/consult/list");
      
      window.location.reload();
    } catch (error) {
      alert("로그인 실패 하였습니다.");
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Samply - React Admin & Dashboard Template</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="text-center mb-5">
                <span style={{ fontSize: "40px", color: "#0576b9" }}>우연</span>
              </div>
              <Card className="overflow-hidden">
                <Row className="g-0">
                  <Col lg={6}>
                    <div className="p-lg-5 p-4">
                      <div className="pb-3 pt-3">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          {props.error && typeof props.error === "string" ? (
                            <Alert color="danger">{props.error}</Alert>
                          ) : null}

                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="아이디"
                              // value="admin@themesbrand.com"
                              className="form-control"
                              placeholder="아이디를 입력 해주세요."
                              type="text"
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="비밀번호"
                              // value="123456"
                              type="password"
                              required
                              placeholder="비밀번호를 입력 해주세요."
                            />
                          </div>
                          <div className="mt-3">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              로그인
                            </button>
                          </div>
                          {/* <div className="mt-4 text-center">
                            <p className="mb-0">
                              Don't have an account ?{" "}
                              <a
                                href="/register"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Signup now{" "}
                              </a>{" "}
                            </p>
                          </div> */}
                        </AvForm>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="p-lg-5 p-4 bg-auth h-100 d-none d-lg-block">
                      <div className="bg-overlay"></div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};
