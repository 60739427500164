import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlarmListStore } from "../../store/zustand/state";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
// import { useParams, useHistory } from "react-router-dom";

import { adminRequestPost, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
// import PremiumMessageModal from "./Modal/PremiumMessageModal";
// import ConnectModal from "./Modal/ConnectModal";
import MessageModal from "./MessageModal";

function AlarmList(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [modalMsg, setModalMsg] = useState(null);
  const [name, setName] = useState("");
  const [searchManager, setSearchManager] = useState("");
  const [phone, setPhone] = useState("");
  // const [connectModal, setConnectModal] = useState(false);
  // const [date, setDate] = useState("");
  // const [time, setTime] = useState("");
  // const [MM, setMM] = useState("");
  // const [place, setPlace] = useState("");
  // const [etc, setEtc] = useState("");
  // const [memo, setMemo] = useState("");
  // const [connectID, setConnectID] = useState("");
  // const [manager, setManager] = useState("");
  const [managers, setManagers] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [tableHeight, setTableHeight] = useState(0);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const { list, updateList, updateActiveNum } = useAlarmListStore();
  // const history = useHistory();
  // const { page } = useParams();

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "name",
      text: "이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.userId}`}
            style={{ color: "#495057" }}
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "gender",
      text: "성별",
      sort: true,
    },
    {
      dataField: "userCode",
      text: "userCode",
      sort: true,
    },
    // {
    //   dataField: "senderPhone",
    //   text: "전화번호",
    //   sort: true,
    // },
    {
      dataField: "typeName",
      text: "종류",
      sort: false,
      
    },
    {
      dataField: "targetName",
      text: "보낸 사람",
      sort: false,
      
    },
    {
      dataField: "targetGender",
      text: "보낸 성별",
      sort: false,
      
    },
    {
      dataField: "alarmStatus",
      text: "상태",
      sort: true,
      
    },
    {
      dataField: "sendDate",
      text: "발송시간",
      sort: true,
    },
    
    // {
    //   dataField: "elapsedTime",
    //   text: "elapsedTime",
    //   sort: true,
    // },
    {
      dataField: "shortContent",
      text: "내용",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <span
            onClick={() => {
              setModalMsg(row.content);
            }}
          >
            {cellContent}
          </span>
        );
      },
    },
    {
      dataField: "action",
      text: "재전송",
      formatter: (cellContent, row, rowIndex) => {
        if(row.name && row.name.length > 0){
          return (
            <button
              onClick={(e) =>
                callResend(row.id)
              }
              className="btn btn-primary btn-sm"
            >
              재전송
            </button>
          );
        }
        return null;
      },
    },
  ];

  // const handleManagerChange = (e, id) => {
  //   const newManager = e.target.value;
  //   setProductData((prevData) =>
  //     prevData.map((item) =>
  //       item.id === id ? { ...item, manager: newManager } : item
  //     )
  //   );
  // };

  const defaultSorted = [];
  // const pageOptions = {
  //   sizePerPage: 10,
  //   totalSize: productData.length, // replace later with size(customers),
  //   custom: true,
  // };

  // // Select All Button operation
  // const selectRow = {
  //   mode: "checkbox",
  // };

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callManagerApi();
  }, []);

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          console.log(key);
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const response = await requestGet(`/api/alarmtalk/list2?${params}`);
      setMemberList(response?.data);
      setLastpage(response?.totalPage);
      setTotalCount(response?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  const callResend = async (id) => {
    if (window.confirm("재전송 하시겠습니까?")) {
      try {
        let body = {
          id: id
        };
        await adminRequestPost("/api/alarmtalk/resend", body);
        alert("요청 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        console.log(error?.response);
        alert("요청 실패 하였습니다.");
      }
    }
  };

  useEffect(() => {
    setActiveNum(list.page);
    setName(list.name);
    setSearchManager(list.manager);
    setPhone(list.phone);
    callInfo();
  }, [list, callInfo]);

  const gohistory = () => {
    updateList(activeNum, name, searchManager, phone);
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>{
        let tmpItemStatus = "기타";
        if(item.status){
          tmpItemStatus = item.status === "request"
          ? "발송"
          : item.status === "processing"
          ? "발송"
          : item.status === "success"
          ? "발송"
          : item.status === "cancel"
          ? "취소"
          : "기타" ;
          try{
            if(item.status.startsWith("error")){
              tmpItemStatus = "에러";
            }
          }catch(e){
            console.log(e);
          }
        }
        arr.push({
          id: item.id,
          userId: item.userId,
          name: item.name,
          userCode: item.userCode,
          content: item.content,
          shortContent: item.content.length > 50 ? item.content.substring(0, 50) + "..." : item.content,
          status: item.status,
          sendDate: item.sendDate,
          elapsedTime: item.elapsedTime,
          gender : item.gender,
          type: item.type,
          typeName : item.type === "introduce" ? "소개" : item.type === "like" ? "호감" 
          : item.type === "purchase" ? "구매" 
          : item.type === "match" ? "매칭"  
          : item.type === "like-reject" ? "호감 거절"  
          : item.type === "meeting" ? "만남 안내"  
          : item.type === "safeNumber" ? "만남 안심 번호"  
          : item.type === "feedback" ? "피드백"  
          : "기타",
          alarmStatus: tmpItemStatus,
          targetName: item.targetName ? item.targetName : "",
          targetGender: item.targetGender ? item.targetGender : "",
        })
      });
        
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  useEffect(() => {
    const updateTableHeight = () => {
      // const topBarHeight = topBarref.current
      //   ? topBarref.current.offsetHeight
      //   : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>알림 발송 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>알림 발송된 목록</h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>이름</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="이름을 입력해 주세요."
                                          aria-label="이름을 입력해 주세요."
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                          // onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>매니저 이름</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <select
                                          id="counselor_genderLabel"
                                          className="noborder form-select h-100"
                                          value={searchManager}
                                          onChange={(e) =>
                                            setSearchManager(e.target.value)
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          <option value="">전체</option>
                                          {managers?.map((item, i) => {
                                            return (
                                              <option value={item.name} key={i}>
                                                {item.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>연락처</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="연락처를 입력해 주세요."
                                          aria-label="연락처를 입력해 주세요."
                                          value={phone}
                                          onChange={(e) =>
                                            setPhone(e.target.value)
                                          }
                                          // onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
       {modalMsg ? (
        <MessageModal Msg={modalMsg} setMsg={setModalMsg} />
      ) : null}
      {/*{connectModal ? (
        <ConnectModal
          connectID={connectID}
          setConnectID={setConnectID}
          setConnectModal={setConnectModal}
          date={date}
          setDate={setDate}
          time={time}
          setTime={setTime}
          MM={MM}
          setMM={setMM}
          place={place}
          setPlace={setPlace}
          etc={etc}
          setEtc={setEtc}
          memo={memo}
          setMemo={setMemo}
          manager={manager}
          setManager={setManager}
        />
      ) : null} */}
    </React.Fragment>
  );
}

export default AlarmList;
