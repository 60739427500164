import React, { useState, useEffect } from "react";


import { withRouter, Link, useHistory } from "react-router-dom";

export default function LoginRedirectPage(props) {
    const history = useHistory();

  useEffect(() => {
    let type = localStorage.getItem("loginUserType");
      if(type && type === "manager"){
        history.push("/consult/member/list");
      } else {
        history.push("/consult/list");
      }
      // history.push("/consult/list");
      
      window.location.reload();
  }, [history]);



  return (
    <React.Fragment>
      <div className="custompage-content">
        
      </div>
    </React.Fragment>
  );
}
