import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import "react-rangeslider/lib/index.css";
import { adminUserPut, requestGet } from "../../apis/request";
import "./consult.scss";
import moment from "moment";

function ConsultRead(props) {
  const [data, setData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [status, setStatus] = useState(1);
  const [manager, setManager] = useState("");
  const [managers, setManagers] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const hours = [
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
  ];
  const [disableHours, setDisableHours] = useState([]);
  const { id } = useParams();

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callManagerApi();
  }, []);

  const callAPI = useCallback(async () => {
    const response = await requestGet(`/api/counseling/detail/${id}`);
    setData(response.data);
  }, [id]);

  useEffect(() => {
    if (id) {
      callAPI();
    }
  }, [callAPI, id]);

  useEffect(() => {
    if (data !== null) {
      setEditData({
        after_commnet: data.after_commnet,
        assigned_datetime: data.assigned_datetime,
        before_comment: data.before_comment,
        birth_year: data.birth_year,
        counselingDates: data.counselingDates,
        counselor_assignment: data.counselor_assignment,
        counselor_gender: data.counselor_gender,
        createdAt: data.createdAt,
        education: data.education,
        gender: data.gender,
        id: data.id,
        ideal_ability: data.ideal_ability,
        ideal_appearance: data.ideal_appearance,
        ideal_character: data.ideal_character,
        location: data.location,
        recommender: data.recommender,
        marketing_agree: data.marketing_agree,
        name: data.name,
        phone: data.phone,
        privacy_agree: data.privacy_agree,
        status: data.status,
        region: data.region,
        kakaoTalkId: data.kakaoTalkId,
        subPath: data.subPath,
      });
      setDate(
        data.consultation_date
          ? moment(data.consultation_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD")
      );
      setHour(
        data.consultation_date
          ? moment(data.consultation_date).format("HH")
          : ""
      );
      setMinute(
        data.consultation_date
          ? moment(data.consultation_date).format("mm")
          : ""
      );
      setStatus(String(data.status));
      setManager(data.counselor_id ? String(data.counselor_id) : "");
    }
  }, [data]);

  const changStatus = (value) => {
    setStatus(value);
  };
  const changManager = (value) => {
    setManager(value);
  };

  const onClickStatus = async () => {
    if (window.confirm("수정 하시겠습니까?")) {
      if (status === "2" && !manager) {
        alert("상담 확정시 담당자 필수 입니다.");
      } else if ((status === "2" && !hour) || (status === "2" && !minute)) {
        alert("상담 확정시 상담일정 시 분 필수 입니다.");
      } else {
        try {
          let body =
            status === "2"
              ? {
                  id: Number(id),
                  status: Number(status),
                  counselor_id: manager,
                  consultation_date: `${date} ${hour}:${minute}:00`,
                  before_comment: editData.before_comment,
                }
              : status === "4" || status === "6"
              ? {
                  id: Number(id),
                  status: Number(status),
                  counselor_id: manager,
                  before_comment: editData.before_comment,
                }
              : {
                  id: Number(id),
                  status: Number(status),
                  before_comment: editData.before_comment,
                };
          await adminUserPut(`/api/counseling/status`, body);
          alert("변경 성공 하였습니다.");
        } catch (error) {
          console.log(error);
          alert("변경 실패 하였습니다.");
        }
      }
    }
  };

  const callDateApi = useCallback(async () => {
    if (manager && status === "2") {
      try {
        const params = new URLSearchParams();
        params.append("date", moment(date).format("YYYY-MM-DD"));
        params.append("userId", manager);
        const response = await requestGet(
          `/api/counseling/date/info?${params}`
        );
        setDisableHours(response?.data?.times);
      } catch (error) {
        console.log(error);
      }
    }
  }, [manager, date, status]);

  useEffect(() => {
    callDateApi();
  }, [callDateApi, manager, date, status]);

  const handleChange = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>상담신청 회원 상세보기</title>
        </MetaTags>
        <div className="container">
          <Card>
            <CardBody>
              <div className="d-flex flex-column mainBottomMargin">
                <h3 className="MainText">상담 신청 회원 상세보기</h3>
              </div>
              <div className="row">
                <div id="formContainer" className="col-lg-12">
                  <div id="uploadResumeStepFormContent">
                    <Row>
                      <Col lg={6}>
                        <div className="mottomMargin45">
                          <label for="firstNameLabel" className="labelText">
                            지사
                          </label>
                          <div className="consultText">
                            {editData?.location}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label
                            for="counselor_genderLabel"
                            className="labelText"
                          >
                            희망상담사 성별
                          </label>
                          <div className="consultText">
                            {editData?.counselor_gender}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="firstDateLabel" className="labelText">
                            1순위 상담 일정
                          </label>
                          <div className="consultText">
                            {editData?.counselingDates[0]}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="secondDateLabel" className="labelText">
                            2순위 상담 일정
                          </label>
                          <div className="consultText">
                            {editData?.counselingDates[1]}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="nameLabel" className="labelText">
                            성함
                          </label>
                          <div className="consultText">{editData?.name}</div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="phoneLabel" className="labelText">
                            연락처
                          </label>
                          <div className="consultText">{editData?.phone}</div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="kakaoTalkIdLabel" className="labelText">
                            카카오톡ID
                          </label>
                          <div className="consultText">{editData?.kakaoTalkId}</div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="birth_yearLabel" className="labelText">
                            출생년도
                          </label>
                          <div className="consultText">
                            {editData?.birth_year}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="genderLabel" className="labelText">
                            성별
                          </label>
                          <div className="consultText">{editData?.gender}</div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="educationLabel" className="labelText">
                            최종학력
                          </label>
                          <div className="consultText">
                            {editData?.education}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* <label className="labelText subBottomMargin18">
                      이성 선호도(총점 10점)
                    </label> */}
                    <Row>
                      {/* <Col lg={4} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label
                            for="contactInformationLabel"
                            className="labelText"
                          >
                            외모
                          </label>
                          <div className="consultText">
                            {editData?.ideal_appearance}
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label
                            for="contactInformationLabel"
                            className="labelText"
                          >
                            능력&집안
                          </label>
                          <div className="consultText">
                            {editData?.ideal_ability}
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label
                            for="contactInformationLabel"
                            className="labelText"
                          >
                            성격
                          </label>
                          <div className="consultText">
                            {editData?.ideal_character}
                          </div>
                        </div>
                      </Col> */}
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="recommenderLabel" className="labelText">
                            추천인
                          </label>
                          <div className="consultText">
                            {editData?.recommender}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="recommenderLabel" className="labelText">
                            거주 지역
                          </label>
                          <div className="consultText">
                            {editData?.region}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className="mottomMargin45">
                          <label for="recommenderLabel" className="labelText">
                            가입 경로
                          </label>
                          <div className="consultText">
                            {editData?.subPath}
                          </div>
                        </div>
                      </Col>
                      <Row>
                        <Col lg={7}>
                          <div className="checkMarginBottom checkMarginTop">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input customCheckBox"
                                id="privacy_agree"
                                name="privacy_agree"
                                readOnly
                                checked={editData?.privacy_agree}
                              />
                              <label className="labelText" for="privacy_agree">
                                개인정보 수집 및 이용에 동의
                                <br />
                              </label>
                            </div>
                          </div>
                          <div className="checkMarginBottom mottomMargin45">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input customCheckBox"
                                id="marketing_agree"
                                name="marketing_agree"
                                readOnly
                                checked={editData?.marketing_agree}
                              />
                              <label
                                className="labelText"
                                for="marketing_agree"
                              >
                                마케팅 활용에 동의
                                <br />
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} className="d-flex flex-column">
                          <div className="mottomMargin45">
                            <label className="labelText" for="marketing_agree">
                              비고
                            </label>

                            <textarea
                              className="js-input-mask form-control customInput"
                              name={"before_comment"}
                              value={editData?.before_comment}
                              placeholder={`비고를 입력해 주세요.`}
                              aria-label={`비고를 입력해 주세요.`}
                              onChange={handleChange}
                              rows={10}
                              style={{ resize: "none" }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-2">
                            <label className="labelText" for="marketing_agree">
                              상태
                            </label>

                            <select
                              className="form-select customInput"
                              name="ideal_ability"
                              style={{ maxWidth: "10rem" }}
                              value={status}
                              onChange={(e) => changStatus(e.target.value)}
                            >
                              <option value={1}>신청</option>
                              <option value={2}>상담 확정</option>
                              <option value={3}>보류</option>
                              <option value={4}>거절</option>
                              <option value={5}>상담 종료</option>
                              <option value={6}>연락중</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      {status === "2" || status === "4" || status === "6" ? (
                        <Row>
                          <Col lg={6}>
                            <div className="mb-2">
                              <label
                                className="labelText"
                                for="marketing_agree"
                              >
                                담당자
                              </label>

                              <select
                                className="form-select customInput"
                                name="ideal_ability"
                                style={{ maxWidth: "10rem" }}
                                value={manager}
                                onChange={(e) => changManager(e.target.value)}
                              >
                                {!manager ? (
                                  <option value="">선택해주세요.</option>
                                ) : null}
                                {managers?.map((item, i) => {
                                  return (
                                    <option value={item.id} key={i}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {status === "2" ? (
                        <Col lg={5} className="d-flex flex-column">
                          <div className="mottomMargin45">
                            <label for="firstDateLabel" className="labelText">
                              상담 일정
                            </label>
                            <div className="input-group">
                              <input
                                type="date"
                                className="js-input-mask form-control customInputLeft"
                                name="firstDate"
                                id="firstDateLabel"
                                min={moment().format("YYYY-MM-DD")}
                                max="9999-12-31"
                                value={date}
                                onChange={(e) =>
                                  setDate(
                                    moment(e.target.value).format("YYYY-MM-DD")
                                  )
                                }
                                style={{ maxWidth: "9rem" }}
                              />
                              <select
                                className="form-select customInputRight"
                                name="firstDateTime"
                                style={{
                                  maxWidth: "4rem",
                                }}
                                value={hour}
                                onChange={(e) => setHour(e.target.value)}
                              >
                                {!hour ? <option value="">시</option> : null}
                                {hours?.map((item, i) => {
                                  return (
                                    <option
                                      value={item}
                                      key={i}
                                      disabled={disableHours.includes(item)}
                                    >
                                      {item}
                                    </option>
                                  );
                                })}
                              </select>
                              <select
                                className="form-select customInputRight"
                                name="firstDateTime"
                                style={{
                                  maxWidth: "4rem",
                                }}
                                value={minute}
                                onChange={(e) => setMinute(e.target.value)}
                              >
                                {!minute ? <option value="">분</option> : null}
                                <option value="00">00</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                              </select>
                            </div>
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                  <div id="stickyBlockEndPoint"></div>
                </div>
              </div>
              <Button color="primary" onClick={() => onClickStatus()}>
                수정
              </Button>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConsultRead;
