import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "./modal.scss";
import {
  adminRequestPost,
  adminUserPut,
  requestGet,
} from "../../../apis/request";
import moment from "moment";

function RefundModal({ setModal, id ,recallApi}) {
  const [value, setValue] = useState("");
  const [memo, setMemo] = useState("");
  const [refundPrice, setRefundPrice] = useState("");
  const [refundDate, setRefundDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [penaltyPrice, setPenaltyPrice] = useState("");

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  

  const callApi = async () => {
    if(refundDate){
      
    } else {
      alert("환불일을 선택해 주세요.");
      return;
    }
    if(endDate){
      
    } else {
      alert("기간제 종료일을 선택해 주세요.");
      return;
    }
    if(refundPrice){
    } else {
      alert("환불할 금액을 입력해 주세요.");
      return;
    }
    try {
      
      let body = {
        id: id,
        refundPrice:refundPrice?Number(refundPrice.replace(/\D/g, "")):0,
        memo:memo,
        refundDate:refundDate?refundDate:moment().format("YYYY-MM-DD"),
        endDate:endDate?endDate:moment().format("YYYY-MM-DD"),
        penaltyPrice:penaltyPrice?Number(penaltyPrice.replace(/\D/g, "")):0
      };
      await adminUserPut(`/api/subscription/refund`, body);
      alert("환불 완료 되었습니다.");
      recallApi();
      setModal(false);
      
    } catch (error) {
      console.log(error);
      if (error && error.data && error.data.message) {
        alert("환불을 할 수 없습니다." + error.data.message);
      } else {
        alert("환불을 할 수 없습니다.");
      }
      recallApi();
      setModal(false);
    }
  };

  const onClose = async () => {
    setModal(false);
  };

  const onChangePrice = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    const formattedNumber = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setRefundPrice(formattedNumber);
  };
  function numberToKorean(number) {
    let num = number ? number : "0";
    if (typeof number === "string") {
      num = number.replace(/\D/g, "");
    }
    const units = ["", "만", "억", "조", "경"];
    const nums = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
    let result = "";
    let unit = 10000;

    if (num === 0) {
      return "영";
    }

    for (let u of units) {
      const curr = num % unit;
      num = Math.floor(num / unit);

      if (curr !== 0) {
        let currStr = "";
        const currArr = String(curr).split("").reverse();

        for (let i = 0; i < currArr.length; i++) {
          const digit = currArr[i];
          if (digit !== "0") {
            currStr =
              nums[parseInt(digit)] +
              (i > 0 ? ["", "십", "백", "천"][i] : "") +
              currStr;
          }
        }
        result = currStr + u + result;
      }

      if (num === 0) {
        break;
      }
    }

    // "일"을 제거하는 조건을 보다 구체적으로 설정하여, "일억"과 같은 경우에는 "일"을 제거하지 않음
    return (result += "원");
  }
  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              <h2>결제 조회</h2>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`priceLabel`} className="labelText">
                      가격
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="priceLabel"
                      id="priceLabel"
                      placeholder="환불해줄 금액을 입력해 주세요."
                      aria-label="환불해줄 금액을 입력해 주세요."
                      value={refundPrice}
                      onChange={(e) => onChangePrice(e)}
                    />
                    <div>{numberToKorean(refundPrice)}</div>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`dateLabel`} className="labelText">
                      환불일
                    </label>
                    <input
                      type="date"
                      className="js-input-mask form-control customInputLeft"
                      name="firstDate"
                      id="firstDateLabel"
                      value={refundDate}
                      onChange={(e) =>
                        {
                          if (e.target.value) {
                            setRefundDate(moment(e.target.value).format("YYYY-MM-DD"));
                          } else {
                            setRefundDate("");
                          }
                          console.log(refundDate);
                        }
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`dateLabel`} className="labelText">
                      기간제 종료일
                    </label>
                    <input
                      type="date"
                      className="js-input-mask form-control customInputLeft"
                      name="endDate"
                      id="endDateLabel"
                      value={endDate}
                      onChange={(e) =>
                        {
                          if (e.target.value) {
                            setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                          } else {
                            setEndDate("");
                          }
                          console.log(endDate);
                        }
                      }
                    />
                  </div>
                </Col>
              </Row>
              {/* <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`penaltyPriceLabel`} className="labelText">
                      가격
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="penaltyPriceLabel"
                      id="penaltyPriceLabel"
                      placeholder="패널티 금액을 입력해 주세요."
                      aria-label="패널티 금액을 입력해 주세요."
                      value={penaltyPrice}
                      onChange={(e) => onChangePrice(e)}
                    />
                    <div>{numberToKorean(penaltyPrice)}</div>
                  </div>
                </Col>
              </Row> */}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText" for="marketing_agree">
                      메모
                    </label>
                    <textarea
                      className="js-input-mask form-control customInput"
                      value={memo}
                      placeholder={`메모을 입력해 주세요.`}
                      aria-label={`메모을 입력해 주세요.`}
                      onChange={(e) => setMemo(e.target.value)}
                      rows={5}
                      style={{ resize: "none" }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-3">
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onClose()}
                  >
                    닫기
                  </Button>
                  <Button
                    id="masterAccesBtn"
                    color="primary"
                    onClick={() => callApi()}
                  >
                    등록
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default RefundModal;
