import React, { useCallback, useEffect, useRef, useState } from "react";
import { useConsultRequestListStore } from "../../store/zustand/state";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

import { adminUserPut, requestGet, requestDelete } from "../../apis/request";

import { useHistory } from "react-router-dom";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import "./consult.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";

function RequestMemberList(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [username, setuserName] = useState("");
  const [counselor, setCounselor] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [managers, setManagers] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [managerRole, setManagerRole] = useState(false);

  const history = useHistory();
  const { list, updateList, updateActiveNum } = useConsultRequestListStore();

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setManagerRole(localStorage.getItem("loginUserType") === "manager");
    callManagerApi();
  }, []);

  const handleAcceptClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (window.confirm("승인 하시겠습니까?")) {
      try {
        let body = {
          id: row.id,
        };
        await adminUserPut(`/api/member/join/accept`, body);
        alert("승인 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert("승인 실패 하였습니다.");
      }
    }
  };

  const handlePendingClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (window.confirm("보류 하시겠습니까?")) {
      try {
        let body = {
          id: row.id,
        };
        await adminUserPut(`/api/member/join/pending`, body);
        alert("보류 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert("보류 실패 하였습니다.");
      }
    }
  };

  const handleRejectClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (window.confirm("거절 하시겠습니까?")) {
      try {
        let body = {
          id: row.id,
        };
        await adminUserPut(`/api/member/join/reject`, body);
        alert("거절 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert("거절 실패 하였습니다.");
      }
    }
  };
  const handleWidthrawalClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        let body = {
          id: row.id,
        };
        await requestDelete(`/api/member/${row.id}`);
        alert("삭제 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert(error?.data?.message);
      }
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.id}/wait`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "name",
      text: "이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.id}/wait`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "phone",
      text: "연락처",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.id}/wait`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "recommendCode",
      text: "추천코드",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.id}/wait`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "gender",
      text: "성별",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.id}/wait`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "birthday",
      text: "생년월일",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.id}/wait`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "regDate",
      text: "가입일시",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.id}/wait`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "action",
      text: "승인",
      formatter: (cellContent, row, rowIndex) => {
        return managerRole? null :(
          <button
            onClick={(e) => handleAcceptClick(e, row, rowIndex)}
            className="btn btn-primary btn-sm"
          >
            승인
          </button>
        );
      },
    },
    // {
    //   dataField: "action",
    //   text: "보류",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return (
    //       <button
    //         onClick={(e) => handlePendingClick(e, row, rowIndex)}
    //         className="btn btn-warning btn-sm"
    //       >
    //         보류
    //       </button>
    //     );
    //   },
    // },
    {
      dataField: "action",
      text: "거절",
      formatter: (cellContent, row, rowIndex) => {
        return managerRole? null :(
          
          <button
            onClick={(e) => handleRejectClick(e, row, rowIndex)}
            className="btn btn-danger btn-sm"
          >
            거절
          </button>
        );
      },
    },
    {
      dataField: "action",
      text: "삭제",
      formatter: (cellContent, row, rowIndex) => {
        return managerRole? null :(
          <button
            onClick={(e) => handleWidthrawalClick(e, row, rowIndex)}
            className="btn btn-dark btn-sm"
          >
            삭제
          </button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const memberResponse = await requestGet(
        `/api/member/join/list?${params}`
      );
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(() => {
    setuserName(list.name);
    setActiveNum(list.page);
    setCounselor(list.counselor);
    callInfo();
  }, [callInfo, list]);

  const gohistory = () => {
    updateList(username, 1, counselor);
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          name: item.name,
          birthday: item.birthday === "Invalid date" ? "" : item.birthday,
          gender: item.gender,
          phone: item.phone,
          recommender: item.recommender,
          regDate: item.regDate,
          recommendCode: item.recommendCode,
          recommendId: item.recommendId,
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const handleRowClick = (e, row) => {
    e.stopPropagation();
    e.preventDefault();
    window.location.href = `/user/${row.id}/wait`;
  };

  // rowEvents 객체 정의
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(e, row, rowIndex);
    },
  };

  const handlename = (e) => {
    setuserName(e.target.value);
  };
  const handleCounselor = (e) => {
    setCounselor(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      gohistory();
    }
  };

  useEffect(() => {

    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>승인대기 회원 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>
                            승인대기 회원 목록
                          </h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>이름</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="이름을 입력해 주세요."
                                          aria-label="이름을 입력해 주세요."
                                          value={username}
                                          onChange={handlename}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                {/* <Col lg={12} className="d-flex p-0 flex-column">
                                <div>
                                  <input
                                    type="text"
                                    className="js-input-mask form-control customSearchInput"
                                    name="name"
                                    id="nameLabel"
                                    placeholder="이름을 입력해 주세요."
                                    aria-label="이름을 입력해 주세요."
                                    value={username}
                                    onChange={handlename}
                                    onKeyDown={handleKeyPress}
                                  />
                                </div>
                              </Col> */}
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>매니저</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <select
                                          id="counselor_genderLabel"
                                          className="noborder form-select h-100"
                                          value={counselor}
                                          onChange={(e) =>
                                            setCounselor(e.target.value)
                                          }
                                          style={{
                                            maxWidth: "9rem",
                                          }}
                                        >
                                          <option value="">전체</option>
                                          {managers?.map((item, i) => {
                                            return (
                                              <option value={item.name} key={i}>
                                                {item.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  rowEvents={rowEvents} // rowEvents 속성 추가
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RequestMemberList;
