import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { adminUserPut, requestGet } from "../../apis/request";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import "./member.scss";
import SleepUserModal from "./Modal/SleepUserModal";
import EndDateModal from "./Modal/EndDateModal";
import RefundModal from "./Modal/RefundModal";

function TimePage({ id, resetAPI }) {
  const [data, setData] = useState({});
  const [sleepModalOpen, setSleepModalOpen] = useState(false);
  const [endDateModalOpen, setEndDateModalOpen] = useState(false);
  const [endDateData, setEndDateData] = useState(null);
  const [refundPrice, setRefundPrice] = useState(null);
  const [dataId, setDataId] = useState(null);
  const [value, setValue] = useState({});
  const [productData, setProductData] = useState([]);
  const [productData1, setProductData1] = useState([]);
  const [productData2, setProductData2] = useState([]);
  const [productData3, setProductData3] = useState([]);
  const [productData4, setProductData4] = useState([]);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [useOldCalculate, setUseOldCalculate] = useState(false);
  const [refundId, setRefundId] = useState(null);

  const columns = [
    {
      dataField: "name",
      text: "상품",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "price",
      text: "가격(원)",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "time",
      text: "기간(일)",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "buyDate",
      text: "구매일자",
      headerClasses: "tableNoCursor",
      classes: "tableNoCursor",
    },
  ];
  const columns1 = [
    {
      dataField: "startDate",
      text: "시작일",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "endDate",
      text: "종료일",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {cellContent}
            <Button
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={(e) => openEndDateModal(e, cellContent)}
            >
              변경
            </Button>
          </div>
        );
      },
    },
    {
      dataField: "useDate",
      text: "사용일",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "day",
      text: "잔여일",
      headerClasses: "tableNoCursor",
      classes: "tableNoCursor",
    },
  ];
  const columns2 = [
    {
      dataField: "intro",
      text: "소개",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "post",
      text: "보낸 호감",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "get",
      text: "받은 호감",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "matching",
      text: "매칭",
      headerClasses: "tableNoCursor",
      classes: "tableNoCursor",
    },
  ];
  const columns3 = [
    {
      dataField: "startDate",
      text: "시작일",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "endDate",
      text: "종료일",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "reason",
      text: "사유",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "memo",
      text: "비고",
      headerClasses: "tableNoCursor",
      classes: "tableNoCursor",
    },
  ];
  const columns4 = [
    {
      dataField: "purchaseDate",
      text: "구매일",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "startDate",
      text: "시작일",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "endDate",
      text: "종료일",
      headerClasses: "tableBorderRight tableNoCursor",
      classes: "tableBorderRight tableNoCursor",
    },
    {
      dataField: "days",
      text: "기간",
      headerClasses: "tableNoCursor",
      classes: "tableNoCursor",
    },
  ];

  const callApi = async () => {
    try {
      const response = await requestGet(`/api/subscription/detail/${id}`);
      if(response.data){
        const productPriceTmp = response.data.productPrice
        let refundPriceTmp = response.data.refundPrice
        const usedDaysTmp = response.data.usedDays
        const daysTmp = response.data.days
        // if(productPriceTmp && refundPriceTmp && usedDaysTmp && daysTmp){
        //   refundPriceTmp = productPriceTmp - 100000 - ((productPriceTmp - 100000) * (usedDaysTmp / daysTmp));
        //   response.data.refundPrice = refundPriceTmp;
        // }
        var useOld = true
        setUseOldCalculate(true);
        if(response.data.purchaseDate){
          const parsedDate = moment(response.data.purchaseDate, "YYYY-MM-DD HH:mm:ss");
          // 기준 날짜 설정 (2024년 10월 1일)
          const referenceDate = moment("2024-10-01", "YYYY-MM-DD");
          // 날짜 비교
          if (parsedDate.isAfter(referenceDate)) {
              useOld = false;
              setUseOldCalculate(false);
          }
        }
        if(useOld){
          if(productPriceTmp && usedDaysTmp && daysTmp){
            refundPriceTmp = productPriceTmp * (usedDaysTmp / daysTmp) - 100000 ;
            response.data.refundPrice = refundPriceTmp;
          }
        } else {
          //계산식 확인 필요 
          // if(productPriceTmp && usedDaysTmp && daysTmp){
          //   refundPriceTmp = productPriceTmp * (usedDaysTmp / daysTmp) * 0.8 ;
          //   response.data.refundPrice = refundPriceTmp;
          // }
          if(productPriceTmp && usedDaysTmp && daysTmp){
            refundPriceTmp = productPriceTmp * (usedDaysTmp / daysTmp) - 100000 ;
            response.data.refundPrice = refundPriceTmp;
          }
        }
        
      }
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id && resetAPI) {
      try {
        callApi();
      } catch (error) {
        console.log(error);
      }
    }
  }, [id, resetAPI]);

  const openEndDateModal = (e, date) => {
    e.stopPropagation();
    setEndDateData(
      date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
    );
    setEndDateModalOpen(true);
  };

  const callNoSleep = async () => {
    if (window.confirm("휴면을 해제 하시겠습니까?")) {
      try {
        let body = {
          id: dataId,
        };
        await adminUserPut(`/api/subscription/sleep/end`, body);
        callApi();
        alert("휴면을 해제 하였습니다.");
      } catch (error) {
        if (error.data.error) {
          alert(error.data.error);
        } else if (error.data.message) {
          alert(error.data.message);
        } else {
          alert("요청 실패 하였습니다.");
        }
      }
    }
  };
  const callRefund = async () => {
    // if (window.confirm("기간제 상품을 환불하시겠습니까?")) {
    //   try {
    //     let body = {
    //       id: dataId,
    //     };
    //     await adminUserPut(`/api/subscription/refund`, body);
    //     callApi();
    //     alert("기간제 상품을 환불하였습니다.");
    //   } catch (error) {
    //     if (error.data.error) {
    //       alert(error.data.error);
    //     } else if (error.data.message) {
    //       alert(error.data.message);
    //     } else {
    //       alert("요청 실패 하였습니다.");
    //     }
    //   }
    // }
    setShowRefundModal(true);
  };

  useEffect(() => {
    if (Object.keys(data)?.length > 0) {
      setDataId(data?.id);
      const refundPriceStr = String(data?.refundPrice ? Math.round(data?.refundPrice)  : 0).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
      const productPriceStr = String(data?.productPrice).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
      const usedDaysStr = String(data?.usedDays).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
      const daysStr = String(data?.days).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      const sleepArr = [];
      const purchaseArr = [];
      data?.sleepDataArray.forEach((item) => {
        sleepArr.push({
          startDate: item.startDate,
          endDate: item.endDate,
          reason: item.because,
          memo: item.now ? "현재상품" : "이전상품",
        });
      });
      data?.subsList.forEach((item) => {
        purchaseArr.push({
          purchaseDate: item.purchaseDate,
          startDate: item.startDate,
          endDate: item.endDate,
          days: item.days,
        });
      });
      setValue({
        status: data?.isSleep
          ? "휴면중"
          : data?.useSubscription
          ? "기간제 사용중"
          : "기간제 사용종료",
      });
      setProductData([
        {
          name: data?.productName,
          price: String(data?.productPrice).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          ),
          time: data?.productDays,
          buyDate: data?.purchaseDate,
        },
      ]);
      setProductData1([
        {
          startDate: data?.startDate,
          endDate: data?.endDate,
          useDate: data?.usedDays,
          day: data?.remianDays,
        },
      ]);
      setProductData2([
        {
          intro: data?.introduce,
          post: data?.sendLike,
          get: data?.receivedLike,
          matching: data?.match,
        },
      ]);
      setProductData3(sleepArr);
      setProductData4(purchaseArr);
      setRefundPrice(
        // `${refundPriceStr}원 = ${productPriceStr}원 - 100,000원 - ((${productPriceStr}원 - 100,000원) * (${usedDaysStr}/${daysStr}))`
        useOldCalculate ? 
        `${refundPriceStr}원 = ${productPriceStr}원 * (${usedDaysStr}/${daysStr}) - 100,000원` :
        `${refundPriceStr}원 = ${productPriceStr}원 * (${usedDaysStr}/${daysStr}) - 100,000원 (2024년10월1일 이후 구매자,계산식 변경 예정)`
      );
    } else {
      setDataId(null);
      setValue({
        status: "미사용",
      });
      setProductData([]);
      setProductData1([]);
      setProductData2([]);
      setProductData3([]);
      setProductData4([]);
      setRefundPrice("");
    }
  }, [data]);

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">기간제</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom" />
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">상태</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">{value?.status}</div>
            </div>
            <Button
              color="primary"
              style={
                value.status === "기간제 사용종료" || value.status === "미사용"
                  ? { marginTop: "6px", display: "none" }
                  : { marginTop: "6px" }
              }
              onClick={() =>
                value.status === "기간제 사용중"
                  ? setSleepModalOpen(true)
                  : callNoSleep()
              }
            >
              {value.status === "휴면중" ? "휴면 해제" : "휴면"}
            </Button>
            <Button
              color="danger"
              style={
                value.status === "기간제 사용종료" || value.status === "미사용"
                  ? { marginTop: "6px",marginLeft:'10px', display: "none" }
                  : { marginTop: "6px",marginLeft:'10px' }
              }
              onClick={() =>
                callRefund()
              }
            >
              환불
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div className="tableWrapContainer">
            <label className="labelText">정보</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={productData}
                  search
                >
                  {(toolkitProps) => (
                    <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive customtable"
                          style={{ overflowX: "unset" }}
                        >
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            classes={
                              "table align-middle table-nowrap tbnom tableborder"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div className="tableWrapContainer">
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <ToolkitProvider
                  keyField="id"
                  columns={columns1}
                  data={productData1}
                  search
                >
                  {(toolkitProps) => (
                    <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive customtable"
                          style={{ overflowX: "unset" }}
                        >
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            classes={
                              "table align-middle table-nowrap tbnom tableborder"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div className="tableWrapContainer">
            <label className="labelText">예상 환불 금액</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                {/* 10,000,000 = 12000000 - 12000000 * 0.1 - 12000000 * (8 / 120) */}
                {refundPrice}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div className="tableWrapContainer">
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <ToolkitProvider
                  keyField="id"
                  columns={columns2}
                  data={productData2}
                  search
                >
                  {(toolkitProps) => (
                    <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive customtable"
                          style={{ overflowX: "unset" }}
                        >
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            classes={
                              "table align-middle table-nowrap tbnom tableborder"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div className="tableWrapContainer">
            <label className="labelText">기간제 구매 내역</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <ToolkitProvider
                  keyField="id"
                  columns={columns4}
                  data={productData4}
                  search
                >
                  {(toolkitProps) => (
                    <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive customtable"
                          style={{ overflowX: "unset" }}
                        >
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            classes={
                              "table align-middle table-nowrap tbnom tableborder"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div className="tableWrapContainer">
            <label className="labelText">휴면 내역</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <ToolkitProvider
                  keyField="id"
                  columns={columns3}
                  data={productData3}
                  search
                >
                  {(toolkitProps) => (
                    <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive customtable"
                          style={{ overflowX: "unset" }}
                        >
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            classes={
                              "table align-middle table-nowrap tbnom tableborder"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {sleepModalOpen ? (
        <SleepUserModal
          setModal={setSleepModalOpen}
          id={dataId}
          recallApi={callApi}
        />
      ) : null}
      {endDateModalOpen ? (
        <EndDateModal
          setModal={setEndDateModalOpen}
          date={endDateData}
          id={dataId}
          recallApi={callApi}
        />
      ) : null}
      {
        showRefundModal ? (
          <RefundModal
            setModal={setShowRefundModal}
            id={dataId}
            recallApi={callApi}
          />
        ) : null
      }
    </React.Fragment>
  );
}

export default TimePage;
