import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDeleteMemberListStore } from "../../store/zustand/state";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useHistory, Link } from "react-router-dom";

import { adminUserPut, requestDelete, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import "../Consult/consult.scss";
import MemberListSearch from "./Modal/MemberListSearch";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import UnRefundSubscribeModal from "../Member/Modal/UnRefundSubscribeModal";
import RefundModal from "../Member/Modal/RefundModal";
import { set } from "lodash";

function DeleteMemberList(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [values, setValues] = useState({});
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [username, setuserName] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("withdrawal");
  const history = useHistory();
  const [totalCount, setTotalCount] = useState(null);
  const [tableHeight, setTableHeight] = useState(0);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [subsListData, setSubsListData] = useState([]);
  const { list, updateList, updateActiveNum } = useDeleteMemberListStore();
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundId, setRefundId] = useState(null);

  const callRefundModal = (id) => {
    setRefundId(id);
    setShowRefundModal(true);
  };
  

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      // formatter: (cellContent, row, rowIndex) => {
      //   return (
      //     <Link to={`/member/detail/${row.id}`} style={{ color: "#000" }}>
      //       {cellContent}
      //     </Link>
      //   );
      // },
    },
    {
      dataField: "name",
      text: "이름",
      sort: true,
      // formatter: (cellContent, row, rowIndex) => {
      //   return (
      //     <Link to={`/member/detail/${row.id}`} style={{ color: "#000" }}>
      //       {cellContent}
      //     </Link>
      //   );
      // },
    },
    {
      dataField: "phone",
      text: "전화번호",
      sort: true,
      // formatter: (cellContent, row, rowIndex) => {
      //   return (
      //     <Link to={`/member/detail/${row.id}`} style={{ color: "#000" }}>
      //       {cellContent}
      //     </Link>
      //   );
      // },
    },
    {
      dataField: "gender",
      text: "성별",
      sort: true,
      // formatter: (cellContent, row, rowIndex) => {
      //   return (
      //     <Link to={`/member/detail/${row.id}`} style={{ color: "#000" }}>
      //       {cellContent}
      //     </Link>
      //   );
      // },
    },
    // {
    //   dataField: "birthday",
    //   text: "생년월일",
    //   sort: true,
    //   // formatter: (cellContent, row, rowIndex) => {
    //   //   return (
    //   //     <Link to={`/member/detail/${row.id}`} style={{ color: "#000" }}>
    //   //       {cellContent}
    //   //     </Link>
    //   //   );
    //   // },
    // },
    {
      dataField: "manager",
      text: "매니저",
      sort: true,
    },
    {
      dataField: "isSublist",
      text: "기간제 유무",
      sort: true,
    },
    // {
    //   dataField: "isSublistCount",
    //   text: "기간제 유무2",
    //   sort: true,
    // },
    {
      dataField: "subsListCount",
      text: "환불 유무",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        if(Number(row.subslistCount) > 0){
          return (
            <button
              onClick={(e) => {
                setSubsListData(row.subsList);
                setModalOpen(true);
              } }
              className="btn btn-dark btn-sm"
            >
              환불 필요
            </button>
          )
        } else {
          return "";
        }
        ;
      },
    },
    // {
    //   dataField: "grade",
    //   text: "등급",
    //   sort: true,
    // },
    {
      dataField: "regDate",
      text: "가입일시",
      sort: true,
      // formatter: (cellContent, row, rowIndex) => {
      //   return (
      //     <Link to={`/member/detail/${row.id}`} style={{ color: "#000" }}>
      //       {cellContent}
      //     </Link>
      //   );
      // },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const memberResponse = await requestGet(`/api/member/list_test?${params}`);
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(() => {
    setuserName(list.name);
    setActiveNum(list.page);
    setStatus(list.status);
    setPhone(list.phone);
    callInfo();
  }, [callInfo, list]);

  const gohistory = () => {
    updateList(username, 1, status, phone);
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          name: item.name,
          birthday: item.birthday === "Invalid date" ? "" : item.birthday,
          gender: item.gender,
          phone: item.phone,
          dDay: item.dDay,
          recommendCode: item.recommendCode,
          regDate: item.regDate,
          received: item.received,
          grade: item.grade,
          sent: item.sent,
          isSleep: item.isSleep ? "휴면" : "정상",
          manager: item.manager,
          subslistCount: item.subslist? String(item.subslist.length) : "0",
          isSublist: item.Subscriptions && item.Subscriptions.length > 0 ? "기간제" : "",
          isSublistCount: item.Subscriptions ? String(item.Subscriptions.length) : "0",
          subsList: item.subslist,
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handlename = (e) => {
    setuserName(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      gohistory();
    }
  };

  const handleRejectClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (window.confirm("거절 하시겠습니까?")) {
      try {
        let body = {
          id: row.id,
        };
        await adminUserPut(`/api/member/reject`, body);
        alert("거절 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert("거절 실패 하였습니다.");
      }
    }
  };

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>탈퇴 회원 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>
                            탈퇴 회원 목록
                          </h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>이름</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="이름을 입력해 주세요."
                                          aria-label="이름을 입력해 주세요."
                                          value={username}
                                          onChange={handlename}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>전화번호</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="전화번호를 입력해 주세요."
                                          aria-label="전화번호를 입력해 주세요."
                                          value={phone}
                                          onChange={(e) =>
                                            setPhone(e.target.value)
                                          }
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {modalOpen ? (
        <UnRefundSubscribeModal
          setModal={setModalOpen}
          data={subsListData}
          callRefundModal = {callRefundModal}
        />
      ) : null}
      {showRefundModal ? (
        <RefundModal
          setModal={setShowRefundModal}
          id={refundId}
          recallApi={callInfo}
        />
      ) : null}
    </React.Fragment>
  );
}

export default DeleteMemberList;
